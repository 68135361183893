.status-icon.status-icon-ok {
    color: #02ec81;
}

.status-icon.status-icon-fail{
    color: #ef0419;
}

.status-icon.status-icon-warning {
    color: #ffc514;
}

.align-center-cell {
    text-align: center;
    vertical-align: middle;
}

.status-table {
    font-size: 14px;
}

.inner-app-container {
    position: fixed;
    top: 50px;
    height: 100%;
    width: 100%;
    padding-bottom: 50px;
    overflow-y: scroll;
}

.inner-app-container::-webkit-scrollbar-track, .messages-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #FFFFFF;
}

.inner-app-container::-webkit-scrollbar, .messages-list::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
}

.inner-app-container::-webkit-scrollbar-thumb, .messages-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #868e96;
}

.messages-list {
    max-height: 250px;
    overflow-y: scroll;
    font-size: 14px;
}

.not-found {
    padding-top: 100px;
}

.resources-area-wrapper .tab-content .tab-pane {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.resources-area-wrapper .tab-content {
    padding-left: 0;
    padding-right: 0;
}

.overlay-loader {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.overlay-loader:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.3), rgba(0, 0, 0, .3));
    background: -webkit-radial-gradient(rgba(20, 20, 20,.3), rgba(0, 0, 0,.3));
}

.component-loader {
    z-index: 999;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.component-loader:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.2), rgba(0, 0, 0, .2));
    background: -webkit-radial-gradient(rgba(20, 20, 20,.2), rgba(0, 0, 0,.2));
}

.component-loader .spinner-border {
    position: absolute;
    left: 50%;
    top: 50%;
}

.component-loader-wrapper {
    position: relative;
}

.components-list {
    max-height: 250px;
    overflow-y: scroll;
}

.view-history-wrapper {
    max-height: 400px;
    overflow-y: scroll;
}

.navbar-collapse a.nav-link {
    text-align: end;
}

@media all and (min-width: 480px) {
    .login {
        padding: 60px 0;
    }
  
    .login form {
        margin: 0 auto;
        max-width: 320px;
    }
}

@media (max-width: 1400px) { 
    .status-table {
        font-size: 11px;
    }
}